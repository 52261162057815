import React from 'react';
import Barcode from 'react-barcode';



const BarcodeGenerator = ({ data, onClick }) => {
  return (
<div onClick={onClick} className="box">
     
     <div className="group"> 
     <div className='barcode'>
     <div className='barcode-container'>
           <Barcode value={data}
             format="CODE128"
             width="10%"
             height={60}
             displayValue={false}
             // fontOptions="bold"
          margin={2}
          renderer='svg'
          
             background="transparent"
             lineColor="#000000" />
         </div>
         </div>
     <div className="overlap-group">
         <div className="text-wrapper">МОЯ КАРТА</div>
       </div>
     </div>
     
     </div>
  );
};

export default BarcodeGenerator;


