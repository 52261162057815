import React from "react";
import { QRCode } from 'react-qrcode-logo';

const BalansBonus = ({  namesystem, balans}) => {
  return (
    <>
      <div className="balans-conteiner2">
      <div className="icon-balans"><Coins02Icon/></div>
        <div >  
         
          <div ><div className="balans">{balans} </div> </div>
       
          <div className="namesystem-conteiner"><div className="namesystem">
          {namesystem}
          </div>
          </div>
        </div>
      </div>
    </>
  );
};
// const BalansBonus = ({  onClick,namesystem, balans}) => {
//   return (
//     <div onClick={onClick}>
//       <div className="balans-conteiner">
//         <div style={{flex: "1 auto"}}>
//                <div className="namesystem-conteiner">{namesystem}</div>
//         <div className="balans">
//           <Wallet02Icon /> {balans}
//         </div>     
//         </div>
//     <div className="conteiner-qr">
//         <QRCode
//          value={0}
//           eyeRadius={5}
//           bgColor="transparent"
       
//           size={90}
//         />
//       </div>

  

//       </div>
//     </div>
//   );
// };

export default BalansBonus;


  const Wallet02Icon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={30} height={30} color={"#636363"} fill={"none"} {...props}>
      <path d="M16 14C16 14.8284 16.6716 15.5 17.5 15.5C18.3284 15.5 19 14.8284 19 14C19 13.1716 18.3284 12.5 17.5 12.5C16.6716 12.5 16 13.1716 16 14Z" stroke="currentColor" strokeWidth="1.5" />
      <path d="M18.9 8C18.9656 7.67689 19 7.34247 19 7C19 4.23858 16.7614 2 14 2C11.2386 2 9 4.23858 9 7C9 7.34247 9.03443 7.67689 9.10002 8" stroke="currentColor" strokeWidth="1.5" />
      <path d="M7 7.99324H16C18.8284 7.99324 20.2426 7.99324 21.1213 8.87234C22 9.75145 22 11.1663 22 13.9961V15.9971C22 18.8269 22 20.2418 21.1213 21.1209C20.2426 22 18.8284 22 16 22H10C6.22876 22 4.34315 22 3.17157 20.8279C2 19.6557 2 17.7692 2 13.9961V11.9952C2 8.22211 2 6.33558 3.17157 5.16344C4.11466 4.2199 5.52043 4.03589 8 4H10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
  const Coins02Icon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"} {...props}>
      <path d="M14 18C18.4183 18 22 14.4183 22 10C22 5.58172 18.4183 2 14 2C9.58172 2 6 5.58172 6 10C6 14.4183 9.58172 18 14 18Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M13.1669 20.9689C12.063 21.6239 10.7742 22 9.3975 22C5.31197 22 2 18.688 2 14.6025C2 13.2258 2.37607 11.937 3.03107 10.8331" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );