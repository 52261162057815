import React from 'react';
import TelegramWebApp from './TelegramWebApp';
import { Helmet } from 'react-helmet';

function App() {


  return (
<div>
      <Helmet>
                   <script src="https://telegram.org/js/telegram-web-app.js"></script>
               </Helmet>
      <div className="App">
        <TelegramWebApp />
      </div>
</div>
  );
}

export default App;