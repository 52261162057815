import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useState } from "react";
import { Dialog, DialogContent, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import "swiper/css";
import "swiper/css/pagination";

export default function ImgSlider({ slides = [] }) {
  const [slideInfo, setSlideInfo] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  async function handleClick(id) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}api/gallery${id}`);
    const slideData = await res.json();

    setSlideInfo(slideData);
    setModalIsOpen(true);
  }

  return (
    <div className="img-slider-container">
      <Swiper
        className="mySwiper"
        grabCursor={true}
        modules={[Pagination]}
        pagination={{ clickable: true }}
      >
        {slides.map((slide, i) => (
          <SwiperSlide key={i} onClick={() => handleClick(slide._id)}>
            <div className="slider" style={{ display: "flex", justifyContent: "center" }}>
              <img src={slide.img} alt="карточка" style={{ height: "400px" }} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <Dialog
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        fullWidth
        maxWidth="sm"
        scroll="paper"
      >
        <Box
          sx={{
            position: 'relative',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setModalIsOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ padding: 0 }}>
            {slideInfo && (
              <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <span dangerouslySetInnerHTML={{ __html: slideInfo.text }} />
              </div>
            )}
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}