import React from "react";

const OtherBonus = ({ namesystem, balans }) => {
  return (
    <>
      <div className="balans-conteiner-other">
      <div className="icon-balans"><Coins02Icon/></div>
        <div >  
         
          <div ><div className="balans-other">{balans} </div> </div>
       
          <div className="namesystem-conteiner-other"><div className="namesystem">
          {namesystem}
          </div>
          </div>
        </div>
      </div>
    </>
  );
};
// const OtherBonus = ({ namesystem, balans }) => {
//   return (


//     <div className="balans-conteiner-other">
//       <div>Другие бонусы</div>
//       <div className="balans-other">{balans}<Coins02Icon /></div>
//       <div className="namesystem-conteiner-other">
//         {namesystem}
//       </div>
//     </div>



//   );
// };

export default OtherBonus;


const Coins02Icon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} color={"#000000"} fill={"none"} {...props}>
    <path d="M14 18C18.4183 18 22 14.4183 22 10C22 5.58172 18.4183 2 14 2C9.58172 2 6 5.58172 6 10C6 14.4183 9.58172 18 14 18Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M13.1669 20.9689C12.063 21.6239 10.7742 22 9.3975 22C5.31197 22 2 18.688 2 14.6025C2 13.2258 2.37607 11.937 3.03107 10.8331" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);