import React, { useEffect, useState } from 'react';
import Header from "./component/Header";
import BalansBonus from "./component/BalansBonus";
import OtherBonus from "./component/OtherBonus";
import "./App.css";
import defaultAvatar from "./assets/avatar.jpg"; // Заранее подготовленное изображение
import userSvg from "./assets/user.svg"; // Импортируем SVG файл
import logo from "./assets/logo.png"; // Импортируем SVG файл
import QRCode from 'qrcode.react'; // Убедитесь, что этот пакет установлен
import ButtonQr from './component/ButtonQr';
import BarcodeGenerator from './component/BarcodeGenerator';
import Gallery from "./component/Gallary";
import Preloader from "./component/Preloader";
import ImgSlider from './component/ImgSlider';

function App() {
  const [userData, setUserData] = useState(null);
  const [balances, setBalances] = useState([]);
  const [userPhoto, setUserPhoto] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Добавлено состояние загрузки
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await fetch(`${process.env.REACT_APP_API_URL}api/gallery`);
      const allSlides = await res.json();
      setSlides(allSlides);
    })();
  }, []);

  useEffect(() => {
    let tg = window.Telegram.WebApp;
    tg.expand(); // Раскрываем приложение на весь экран
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    const chatId = window.Telegram.WebApp.initDataUnsafe.user.id;
    const userId = window.Telegram.WebApp.initDataUnsafe.user.id;
    try {
      const response = await fetch(`${process.env.REACT_APP_AUTH_URL}user/${userId}`, {
        headers: {
          'a': chatId,
          'key': process.env.REACT_APP_API_KEY, // Добавляем ключ в заголовки
        },
      });
      const data = await response.json();
      setUserData(data);
      fetchBalances(data.phone);
      const photoUrl = window.Telegram.WebApp.initDataUnsafe.user.photo_url;
      setUserPhoto(photoUrl);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  };

  const [hasOtherBonuses, setHasOtherBonuses] = useState(false);
  useEffect(() => {
    setHasOtherBonuses(balances.filter(balance => balance.name !== "Бонусные баллы").length > 0);
  }, [balances]);

  const fetchBalances = async (phone) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_AUTH_URL}balance/${phone}`, {
        headers: {
          'a': window.Telegram.WebApp.initDataUnsafe.user.id,
          'key': process.env.REACT_APP_API_KEY, // Добавляем ключ в заголовки
        },
      });
      const data = await response.json();
      setBalances(data);
    } catch (error) {
      console.error('Error fetching balances:', error);
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      {isLoading ? ( // Условный рендеринг для загрузки
        <Preloader/> // Здесь можно использовать свой компонент загрузки
      ) : (
        <div className="continer">
          {userData && (
            <>
              <Header
                firstName={userData.firstName}
                lastName={userData.lastName}
                avatar={<img src={userPhoto || logo} alt="Avatar" />}
              />
              <BarcodeGenerator data={userData ? userData.phone : '7999999999'} onClick={openModal} />
              <div className="conteiner-other">
                <div className="conteiner-other-bonus">
                  {balances.length > 0 && (
                    <div className={`balans-conteiner ${hasOtherBonuses ? 'width-50' : 'width-100'}`}>
                      <BalansBonus
                        namesystem={balances.find(balance => balance.name === "Бонусные баллы")?.name || "Бонусные баллы"}
                        balans={balances.find(balance => balance.name === "Бонусные баллы")?.balance || "0"}
                      />
                    </div>
                  )}
                  {balances.filter(balance => balance.name !== "Бонусные баллы").map((balance, index) => (
                    <OtherBonus
                      key={index}
                      namesystem={balance.name}
                      balans={balance.balance}
                    />
                  ))}
                </div>
              </div>
              {isModalOpen && (
                <div className="modal-overlay" onClick={closeModal}>
                  <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div className='name-qr'>{userData.firstName} {userData.lastName}</div>
                    <QRCode value={userData ? userData.phone : ''} size={230} />
                    <button onClick={closeModal}>Закрыть</button>
                  </div>
                </div>
              )}
            </>
          )}
          {/* <Gallery /> */}
          <ImgSlider slides={slides.filter((slide) => slide.isActive)} />
        </div>
      )}
    </>
  );
}

export default App;