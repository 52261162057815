import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const lineAnimation1 = () => keyframes`
    0% {
        transform: translateY(-50%) rotate(60deg) translateX(-100%);
    }
    100% {
        transform: translateY(-50%) rotate(60deg) translateX(0);
    }
`;
const lineAnimation2 = () => keyframes`

    0% {
        transform: translateY(-50%) rotate(20deg) translateX(-100%);
    }
    100% {
        transform: translateY(-50%) rotate(20deg) translateX(0);
    }

`;
const lineAnimation3 = () => keyframes`

    0% {
        transform: translateY(-50%) rotate(-20deg) translateX(-100%);
    }
    100% {
        transform: translateY(-50%) rotate(-20deg) translateX(0);
    }

`;
const lineAnimation4 = () => keyframes`

    0% {
        transform: translateY(-50%) rotate(-237deg) translateX(-100%);
    }
    100% {
        transform: translateY(-50%) rotate(-237deg) translateX(0);
    }

`;

const Line1 = styled.div`
  height: 120px;
  width: 100rem;
  background-color: #000000;
  position: absolute;
  top: 47%;
  animation: ${lineAnimation1} 1s forwards;
`;

const Line2 = styled.div`
  height: 120px;
  width: 100rem;
  background-color: #f3c14a;
  position: absolute;
  top: 62%;
  animation: ${lineAnimation2} 2s forwards;
`;

const Line3 = styled.div`
  height: 120px;
  width: 100rem;
  background-color: #e6dcc7;
  position: absolute;
  top: 20%;
  animation: ${lineAnimation3} 3s forwards;
`;

const Line4 = styled.div`
  height: 120px;
  width: 100rem;
  background-color: #b9d396;
  position: absolute;
  top: 60%;
  animation: ${lineAnimation4} 3s forwards;
`;

const PreloaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.5s ease;
`;

const Preloader = () => {
  const [isVisible, setIsVisible] = useState(true);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000); // Установите время, равное времени завершения анимации
    return () => clearTimeout(timer); // Очистка таймера при размонтировании
  }, []);
  
  return (
    <PreloaderContainer isVisible={isVisible}>
      <Line1 />
      <Line2 />
      <Line3 />
      <Line4 />
    </PreloaderContainer>
  );
};

export default Preloader;