import React, { useState } from "react";
import './style.css'

const Header = ({firstName, lastName, avatar}) => {
   

    function getGreeting() {
        // Получаем текущее время по МСК
        const now = new Date();
        const offset = now.getTimezoneOffset();
        const mskOffset = 3 * 60; // Московское время GMT+3
        const mskTime = new Date(now.getTime() + (mskOffset + offset) * 60 * 1000);
    
        // Получаем часы и минуты
        const hours = mskTime.getHours();
    
        // Определяем приветствие в зависимости от времени суток
        let greeting;
        if (hours >= 6 && hours < 10) {
            greeting = "Доброе утро";
        } else if (hours >= 10 && hours < 18) {
            greeting = "Добрый день";
        } else if (hours >= 18 && hours < 21) {
            greeting = "Добрый вечер";
        } else {
            greeting = "Доброй ночи";
        }
    
        return greeting;
    }

    return (
        <div className="user-conteiner" >
            <div className="user-avatar"> 
            {avatar}
            </div>
            <div className="conteiner-username">
            <div className="user-hello">
            {getGreeting()}
            </div>
            {firstName} {lastName}
            </div>
        </div>
    )

}
export default Header